


































import { Component, Vue } from 'vue-property-decorator';
import { Note } from '@/models/note/Note';
import { NoteActionType } from '@/store/note/actions';

@Component({
    components: {
        // Timeline,
        // TimelineItem,
        CustomerNoteForm: () => import(/* webpackChunkName: "CustomerNoteForm" */ '@/views/customer/note/CustomerNoteForm.vue')
    }
})
export default class CustomerNoteList extends Vue {
    customerId!: string;
    currentPage = 1;

    created() {
        this.customerId = this.$route.params.id;
        this.search();
    }

    mounted() {
    }

    get customerNoteForm(): any {
        return this.$refs.customerNoteForm;
    }

    get listItems(): Note[] {
        return this.$store.getters['note/listItem'];
    }

    addNote() {
        this.customerNoteForm.add();
    }

    search() {
        this.$store.dispatch(NoteActionType.FETCH_DATA, this.customerId);
    }

    delete(note: Note) {
        // this.$bvModal
        //     .msgBoxConfirm(
        //         `Bạn có chắc chắn muốn xóa người dùng: "${note.title}" này?`,
        //         {
        //             title: 'Thông báo xóa',
        //             size: 'sm',
        //             buttonSize: 'sm',
        //             okVariant: 'danger',
        //             okTitle: 'Đồng ý',
        //             cancelTitle: 'Hủy bỏ',
        //             footerClass: 'p-2',
        //             hideHeaderClose: false,
        //             centered: true
        //         }
        //     )
        //     .then(value => {
        //         console.log(value, note.id);
        //     })
        //     .catch((err: any) => {
        //         // An error occurred
        //     });
    }
}
